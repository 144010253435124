import Mohsin from './About-Imagess/mohsin.png'
import Iqbal from './About-Imagess/iqbal.png'
import Umar from './About-Imagess/umar.png'
// import Javed from './About-Imagess/javed.png'
import Measum from './About-Imagess/measum.png'
import Adil from './About-Imagess/adil.png'
import Liaqat from './About-Imagess/liaqat.png'
import Hasnain from './About-Imagess/hasnain.png'
// import Ali from './About-Imagess/ali.png'
// import Sara from './About-Imagess/sara.png'
// import Afsana from './About-Imagess/afsana.png'
// import Ambreen from './About-Imagess/ambreen.png'
import Zagham from './About-Imagess/zagham.png'
// import Anila from './About-Imagess/anila.png'
import Annosha from './About-Imagess/annosha.png'
import Nayyar from './About-Imagess/nayyar.png'
// import Ahtisham from './About-Imagess/ahtisham.png'
// import Jameela from './About-Imagess/jameela.png'
// import Naila from './About-Imagess/naila.png'
import Mehak from './About-Imagess/mehak.png'
// import Tajwar from './About-Imagess/tajwar.png'
import Zahid_1 from './About-Imagess/zahid-1.png'

import Hashmi from './About-Imagess/hashmi.png'
import Mehwish from './About-Imagess/Mehwish.png'
import Ansar from './About-Imagess/ansar.png'
import Shahzaib from './About-Imagess/shahzaib.png'
// import Najwa from './About-Imagess/najwa.png'
import Zahid from './About-Imagess/zahid.png'
import Waqar from './About-Imagess/waqar.png'
import Sehrish from './About-Imagess/sehrish.png'
import Piyar from './About-Imagess/piyar.png'
import Kiran from './About-Imagess/kiran.png'
import Midhat from './About-Imagess/midhat.png'
import Didar from './About-Imagess/didar.png'
import Saba from './About-Imagess/saba.png'


export const aboutData = [
    {
        memberName: "Muhammad Iqbal",
        memberPosition: "CEO & Co-Founder | UI UX Designer",
        memberDetail: "As the CEO of IdeoMetriX, a leading design and technology firm, I am dedicated to spearheading innovation and creativity in every aspect of our operations.",
        teamMemberPhoto: Iqbal,
        link: "https://www.linkedin.com/in/iqbalkakakhail"
    },
    {
        memberName: 'Mohsin Amir',
        memberPosition: "Co-Founder | Graphic Designer",
        memberDetail: " As passionate professional, defined by work ethics, professional integrity, and years of experience of working with national and international organizations. ",
        teamMemberPhoto: Mohsin,
        link: "https://www.linkedin.com/in/mohsin-amir-glt"
    },
    
    // {
    //     memberName:"Umer Farooq",
    //     memberPosition: "Digital Marketer",
    //     memberDetail:`I am a digital native with a holistic knowledge of Digital marketing channels. Being in this field for last two years and have experience dealing with international clients.`,
    //     teamMemberPhoto:Umar,
    //     link: "https://www.linkedin.com/in/umer-farooq-851911182"
    // },
    // 

]

export const employeeData = [
    {
        employeeImage: Umar,
        employeeName: "Umar Farooq",
        employeePosition: "Digital Marketer",
        link: "https://www.linkedin.com/in/umer-farooq-851911182"
    },
    {
        employeeName: "Muhammad Adil",
        employeePosition: "Graphic Designer",
        employeeImage: Adil,
        link: "https://www.linkedin.com/in/adil-asad-a08840187/"
    },
    
    {
        employeeName: "Measum Abbas",
        employeePosition: "Mern-Stack Developer",
        employeeImage: Measum,
        link: "https://www.linkedin.com/in/measum-abbas-99b798199"
    },
    {
        employeeName: "Hasnain Abbas",
        employeePosition: "React Developer",
        employeeImage: Hasnain,
        link: "https://www.linkedin.com/in/hasnain-abbas-4b7345226"
    },
    {
        employeeName: "Liaqat Hussain",
        employeePosition: "Wordpress Developer",
        employeeImage: Liaqat,
        link: "https://www.linkedin.com/in/liaqat-kakakhail-226a81177"
    },

    // {
    //     employeeName: "Zagham Abbas",
    //     employeePosition: "Jr. Graphic Designer",
    //     employeeImage: Zagham,
    //     link: "https://www.linkedin.com/in/zagham-abbas-visual-designer-b55210257"
    // },
    {
        employeeName: "Nayyer Khalil",
        employeePosition: "Jr. UI/UX Designer",
        employeeImage: Nayyar,
        link: "https://www.linkedin.com/in/nayyar-khalil-b83545253"
    },
    {
        employeeName: "Annosha",
        employeePosition: "Jr. UI/UX Designer",
        employeeImage: Annosha,
        link: "https://www.linkedin.com/in/annosha-aslam-79ab7b159"
    },
     
    // {
    //     employeeName: "Mehak kanwal",
    //     employeePosition: "Jr. UI/UX Designer",
    //     employeeImage: Mehak,
    //     link: ""
    // },
    {
        employeeName: "Zahid Hussain",
        employeePosition: "Graphic Designer",
        employeeImage: Zahid_1,
        link: "https://www.linkedin.com/in/zahid-purki-1a24b3251/"
    },

    {
        employeeName: "Ali Hashmi",
        employeePosition: "Digital Marketer",
        employeeImage: Hashmi,
        link: "https://www.linkedin.com/in/ali-hashmi-digital-marketing-expert-9b9512226"
    },

    {
        employeeName: "Mehwish Karim",
        employeePosition: "Program Manager",
        employeeImage: Mehwish,
        link: "https://www.linkedin.com/in/mehwish-karim/"
    },

    {
        employeeName: "Raees Ansar",
        employeePosition: "Backend Developer",
        employeeImage: Ansar,
        link: "https://www.linkedin.com/in/ansar-ali-aa86a421a"
    },

    {
        employeeName: "Shahzaib Ghazi",
        employeePosition: "Book-Keeping Intern",
        employeeImage: Shahzaib,
        link: "https://www.linkedin.com/in/shahzaib-ghazi-761a7a249/"
    },
    {
        employeeName: "Zahid Ali Shah",
        employeePosition: "Book-Keeping Intern",
        employeeImage: Zahid,
        link: "https://www.linkedin.com/in/zahid-ali-shah-9974b8265/"
    },
    {
        employeeName: "Waqar Younus",
        employeePosition: "UI/UX  Designer",
        employeeImage: Waqar,
        link: "https://www.linkedin.com/in/waqaryounus321/"
    },
    {
        employeeName: "Piyar Ali Shah",
        employeePosition: "UX Designer",
        employeeImage: Piyar,
        link: "https://www.linkedin.com/in/piyar-ali-shah-0554392aa/"
    },
    {
        employeeName: "Kiran Zahra",
        employeePosition: "UX Designer",
        employeeImage: Kiran,
        link: "https://www.linkedin.com/in/saba-mehboob-4688232a6/"
    },
    {
        employeeName: "Sehrish Batool ",
        employeePosition: "Program Coordinator",
        employeeImage: Sehrish,
        link: "https://www.linkedin.com/in/sehrish-batool-b3290126b/"
    },
    {
        employeeName: "Midhat Zahra ",
        employeePosition: "Graphic Designer",
        employeeImage: Midhat,
        link: "https://www.linkedin.com/in/midhat-zahra-qasmi-7a9570214/"
    },
    {
        employeeName: "Didar Ali",
        employeePosition: "Graphic Designer",
        employeeImage: Didar,
        link: "https://www.linkedin.com/in/didaraliofficial/"
    },
    {
        employeeName: "Shamsul Saba",
        employeePosition: "Digital Marketer",
        employeeImage: Saba,
        link: "https://www.linkedin.com/in/kiran-zahra-091865297/"
    },


    
]