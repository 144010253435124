const data = [
  {
    jobTitle: "Flutter Developers (2 Positions)",
    desc: "We are looking for a skilled Flutter Developer to join our dynamic team. As a Flutter Developer, you will be responsible for developing and maintaining high-performance mobile applications for both iOS and Android platforms. You will work closely with our design and backend teams to deliver seamless, user-friendly experiences.",
    responsibilities: [
      {
        data: "Develop and maintain cross-platform mobile applications using Flutter.",
      },
      {
        data: "Collaborate with UI/UX designers to implement highly responsive and pixel-perfect user interfaces.",
      },
      {
        data: "Write clean, efficient, and scalable code following best practices and design patterns.",
      },
      {
        data: "Integrate RESTful APIs to connect applications with backend services.",
      },
      {
        data: "Optimize application performance for speed and scalability",
      },
      {
        data: "Identify and fix bugs to ensure the reliability and stability of the application.",
      },
      {
        data: "Participate in code reviews and provide constructive feedback to team members.",
      },
      {
        data: "Stay updated with the latest industry trends and technologies to ensure the applications are up-to-date",
      },
      {
        data: "Deploy and maintain apps on Google Play Store and Apple App Store.",
      },
    ],
    requirements: [
      {
        data: "Proven experience as a Flutter Developer with a solid portfolio of published apps.",
      },
      {
        data: "Proficiency in Dart programming language and Flutter framework.",
      },
      {
        data: "Strong understanding of mobile app development lifecycle, including design, development, testing, and deployment.",
      },
      {
        data: "Experience with state management solutions such as Provider, Riverpod, or Bloc.",
      },
      {
        data: "Familiarity with third-party libraries and APIs integration.",
      },
      {
        data: "Good understanding of Git version control.",
      },
      {
        data: "Experience with Firebase services like Firestore, Authentication, and Cloud Messaging is a plus.",
      },
      {
        data: "Knowledge of native iOS and Android development is a bonus.",
      },
      {
        data: "Strong problem-solving skills and the ability to work both independently and collaboratively.",
      },
      {
        data: "Excellent communication skills and attention to detail.",
      },
    ],
    qualifications: [
      {
        data: "Bachelor's degree in Computer Science, Software Engineering, or a related field.",
      },
      { data: "Experience with Agile/Scrum methodologies." },
      { data: "Prior experience with CI/CD pipelines for Flutter apps." },
    ],
    whatWeOffer: [
      { data: "Project Based Salary" },
      { data: "Flexible working hours with remote work options." },
      { data: "Opportunities for career growth and professional development." },
      { data: "A collaborative and inclusive work environment." },
      { data: "Access to the latest tools and technologies." },
    ],
    lastParas: [
      {
        data: "If you are passionate about mobile development and are excited about working on cutting-edge projects, we would love to hear from you. Apply now to join our innovative team!",
      },
    ],
    postedDate: "14-nov-2024",
    expiresAt: "25-nov-2024",
    formLink: "https://docs.google.com/forms/d/e/1FAIpQLSeOYY-CHjvceNayIu6exNyYNm1hMejU1Jv0kzkDNDWi_QTeYg/viewform?usp=sf_link",
  },
//   {
//     jobTitle: "Digital Marketing Lead",
//     desc: "is looking for an experienced and strategic Digital Marketing Lead to join our team.",
//     responsibilities: [
//       {
//         data: "Develop and implement digital marketing strategies that align with business objectives and drive revenue growth.",
//       },
//       {
//         data: "Create and execute campaigns across multiple digital channels, including social media, email marketing, search engine optimization, paid to advertise, and more",
//       },
//       {
//         data: "Manage and optimize digital marketing campaigns, monitor metrics, and adjust strategies as needed to ensure optimal results.",
//       },
//       {
//         data: " Collaborate with cross-functional teams, including design, product, and analytics, to create and implement campaigns that align with overall marketing initiatives.",
//       },
//       {
//         data: "Stay up-to-date with the latest digital marketing trends, technologies, and best practices.",
//       },
//       {
//         data: " Analyze campaign performance and provide regular reporting to the team.",
//       },
//     ],
//     requirements: [
//       {
//         data: "Bachelor's degree in marketing, communications, or a related field.",
//       },
//       {
//         data: "2+ years of experience in digital marketing, with a track record of driving business results.",
//       },
//       {
//         data: "Strong understanding of digital marketing channels and tools.",
//       },
//       {
//         data: "Excellent communication skills, with the ability to work collaboratively with cross-functional teams..",
//       },
//       {
//         data: "Strong project management skills.",
//       },
//     ],
//     lastParas: [
//       {
//         data: "If you are passionate about digital marketing, have a strategic mindset, and have a proven track record of driving results, we encourage you to apply. ",
//       },
//       {
//         data: "We offer a competitive salary, a comprehensive benefits package, and a dynamic work environment. Join our team and help us grow our business through innovative digital marketing strategies.",
//       },
//       {
//         data: "Females are encouraged to apply",
//       },
//     ],
//     postedDate: "28-feb-2023",
//     expiresAt: "5-march-2023",
//     formLink: "https://forms.gle/dcsmbB2WpabQUGRu8",
//   },
//   {
//     jobTitle: "Program Manager",
//     desc: "seeking an experienced and motivated program manager to join our step-up skill development program. As program manager, you will be responsible for conducting, leading, and managing the step-up training programs, ensuring their productivity and motivation, and guiding them to achieve the targeted goals.",
//     responsibilities: [
//       {
//         data: "Plan and conduct training programs, ensuring that deadlines are met and resources are utilized effectively.",
//       },
//       {
//         data: "Monitor the training programs and team performance, identify areas for improvement and implement appropriate solutions.",
//       },
//       {
//         data: "Establish and maintain relationships with trainers, and trainees to ensure that the program runs smoothly and meets its goals.",
//       },
//     ],
//     requirements: [
//       {
//         data: "Bachelor's degree.",
//       },
//       {
//         data: "2+ years of leadership or management experience.",
//       },
//       {
//         data: "Strong interpersonal and communication skills.",
//       },
//       {
//         data: "Proven ability to manage training.",
//       },
//       {
//         data: "Excellent problem-solving skills and the ability to think strategically.",
//       },
//       {
//         data: "Strong organizational skills and attention to detail.",
//       },
//     ],
//     lastParas: [
//       {
//         data: "We offer a competitive salary, flexible work arrangements, and opportunities for growth and advancement. If you are a motivated and experienced team lead, we encourage you to apply today.",
//       },
//     ],
//     postedDate: "15-feb-2023",
//     expiresAt: "25-feb-2023",
//     formLink:
//       "https://docs.google.com/forms/d/e/1FAIpQLSe9-j-9EzN3znarcD9sS6bC_ZFY6jNz9DBlmDeQwe1jERFHgg/viewform?usp=sf_link",
//   },
];

export default data;
